@tailwind base;
@tailwind components;
@tailwind utilities;

.header{
    height: 65px;
    width: 100%;
    background: #ebfcff;
    display:flex;
    flex-direction: row;
    position: fixed;
    justify-content: space-between;
    align-items: center;
}
.header .navbrand{
    display: flex;
    flex-direction: row;
    justify-self: start;
    height: 100%;
    align-items: center;
}

.header .brandLink{
    text-decoration: none;
    display: block;
    margin-right: 1.0em;
}
.header .logo{
    height: 65px;
    width: 65px;
    margin-right: 0.5rem;
}

.navLinks{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.navLinks .active {
    color: #29b5e8;
    border-bottom: 3.5px solid #29b5e8;
}

.navLinks .navItem {
    text-decoration: none;
    font-size: medium;
    font-weight: bolder;
}
.navLinks a {
    color: #29b5e8;
}

.navLinks a:hover {
    color: white;
    background-color: #29b5e8;
}


.header .logo-name{
    font-size: xxx-large;
    font-weight: bold;
    color:#29b5e8
}

.header .userProfile{
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    margin-right: 0.5em;
}

.header .userProfile .displayName {
    margin: 0;
    font-weight: bold;
    display: flex;
    color: #29b5e8;
    align-self: center;
}


.nodegrid {
    margin-top: 100px;
    position: absolute;
    width: 100%;
}

.toolbox {
    display: flex;
    height: fit-content;
    margin: 0px 5px 20px 5px;
    width: 100%;
    justify-content: space-between;
}

.search-bar {
    height: 35px;
    align-self: center;
}

.grid-button {
  display: flex;
  margin: auto 5px;
  justify-content: center;
  align-items: center;
}

.inputField {
    height: 100%;
    width: 12em;
    border: 1px solid black;
}

.edit-button,
.submit-button,
.cancel-button,
.goback-button,
.search-button,
.add-button {
    display: inline-block;
    padding: 4px 10px 2px;
    height: 100%;
    /* width: 5rem; */
    color: whitesmoke;
    background-color: rgb(4,168,243);
}
.submit-button-disabled {
  display: inline-block;
    padding: 4px 10px 2px;
    height: 100%;
    /* width: 5rem; */
    color: whitesmoke;
    background-color: rgb(144, 204, 232);
}

.edit-button:hover,
.submit-button:hover,
.cancel-button:hover,
.goback-button:hover,
.search-button:hover,
.add-button:hover {
    display: inline-block;
    padding: 4px 10px 2px;
    height: 100%;
    color: #ebfcff;
    background-color: rgb(152, 218, 249);
}
.gen-xy-button {
    display: inline-block;
    padding: 4px 10px 2px;
    height: 100%;
    /* width: 5rem; */
    color: whitesmoke;
    background-color: rgb(4,168,243);
    margin-right: 10px;
  }

.k-toolbar::before {
    content: "";
    height: fit-content;
    margin-inline-start: -8px;
}
.k-pager-numbers .k-link {
    color: rgb(14, 15, 15);
  }
  .k-pager-numbers .k-link:hover, .k-pager-numbers .k-link.k-state-hover {
    color: #141212;
    background-color: rgba(2, 204, 255, 0.08);
  }
  
  .k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus {
    background-color: transparent;
    box-shadow: inset 0 0 0 2px rgba(46, 195, 214, 0.12);
  }
  
  .k-pager-numbers .k-link.k-state-selected {
    color: #1f1b1b;
    background-color: rgba(43, 216, 207, 0.2);
  }
  
.k-list-item.k-selected, .k-selected.k-list-optionlabel {
    color: white;
    background-color: #29b5e8;
}

.k-list-item.k-selected:hover{
    color: white;
    background-color: #29b5e8;
} 

.k-checkbox:checked, .k-checkbox.k-checked {
    background-color: #29b5e8;
    border:#29b5e8;
    color:white;
}

.k-dialog-titlebar {
    background-color: #29b5e8;
    color: white;
}
.nodegrid > .k-grid a {
    color: #0d6efd;
    text-decoration: solid;
}
/* .k-grid a {
    color: #0d6efd;
    text-decoration: solid;
} */
/* @media screen and (max-width: 550px){
    
} */

.k-table-tbody .k-table-row.k-selected, .k-table-list .k-table-row.k-selected {
    background-color: #29b5e879 !important;
}

.fededge-loader {
    color: #29b5e8;
    margin-right: 0.5rem;
}


#root > header > div.userProfile > div > button {
    color: #29b5e8;
    font-weight: bold;
    border: none;
    flex-direction: row-reverse;
}

#root > header > div.userProfile > div > button:hover {
    color: white;
    background-color: #29b5e8;
}

/* #root > header > div.genXY > div > button:hover {
  color: white;
  background-color: #29b5e8;
} */
.k-menu-group .k-item > .k-link:active {
    color: white;
    font-weight: 400;
    background-color: #29b5e8;
}

.fed-notification-overlay {
    display: flex;
    width: 100%;
    margin-top: 65px;
    position: absolute;
    justify-content: flex-start;
    align-items: center;
    z-index: 100;
    flex-direction: column;
}

.k-notification-group {
    width: 50%;
    max-height: 100%;
    display: inline-flex;
    position: fixed;
    flex-direction: column-reverse;
    justify-content: flex-end;
    flex-wrap: nowrap !important;
    position: relative;
    height: 2.5rem;
    right: 0;
    bottom: 0;
}

.k-notification-container,
.k-notification-container .k-notification {
    color: #565656;
    font-weight: bold;
    width: 100%;
    height: 100%;
}

.k-notification-error {
    border-color: #73504c7d;
    color: white;
    background-color: #f2938978;
}

.k-notification-success {
    border-color: #73504c7d;
    color: white;
    background-color: #70f88592;
}

.k-notification-wrap {
    height: 100%;
    display: flex;
    align-content: center;
}

.k-notification-wrap > .k-icon,
.k-notification-wrap > .k-notification-content {
    display: flex;
    align-self: center;
    font-size: medium;
}
.k-pager-numbers .k-link.k-selected {
  color: #29b5e8;
  background-color: whitesmoke;
}